<template>
  <dashboard-page v-if="categoryTrend" :image-header-props="imageHeaderProps" class="margin-bottom-xxl">
    <template v-slot:header>
      <div class="header flex-column">
        <div class="ri-large-headline">{{ categoryTrend.categoryName }}</div>
        <div class="ri-title" v-if="isFirstCashflow">בחודשים האחרונים</div>
        <div class="ri-title" v-else>ב־6 חודשים האחרונים</div>
      </div>
    </template>
    <template v-slot:dashboard>
      <div v-if="isFirstCashflow " class="padding-ml ri-large-body">
        <div class="ri-bold-title margin-bottom-m">ההוצאות בחודש {{prevCashflowMonthName}} ביחס לממוצע בחודשים שקדמו לו</div>
        <category-month-vs-average-graph :month-spending="prevMonthAmount" :month-name="prevCashflowMonthName" :average="previousMonthsAverage" />
      </div>
      <div v-else>
        <category-progress-overview v-if="categoryTrend.quarterlyChangePercentage"
                                    :category-name="categoryTrend.categoryName"
                                    :change-absolute-amount="categoryTrend.quarterlyChangeAmount"
                                    :change-percent="categoryTrend.quarterlyChangePercentage"
                                    :positiveChange="isPositiveChange"/>
        <div v-else class="padding-ml ri-large-body">
          בין החודשים {{ sixMonthsAgo | getMonthAndShortYear }} - {{ lastMonth | getMonthAndShortYear }} אלה היו הנתונים
          שלך בקטגוריית "<span class="ri-large-bold-body">{{ categoryTrend.categoryName }}</span>":
        </div>
        <separator/>
        <div class="padding-ml">
          <div class="ri-bold-title margin-bottom-m">סה״כ הוצאות בחלוקה לתקופות</div>
          <category-quarter-vs-quarter-graph :current-quarter-amount="currentQuarterAmount"
                                             :last-quarter-amount="lastQuarterAmount"/>
        </div>
      </div>
    </template>
    <div class="category-cards flex-column padding-ml">
      <category-trend-businesses-card :top-businesses="categoryTrend.topBusinessNames"/>
      <category-trend-graph-card :category-name="categoryTrend.categoryName" :graph-data="categoryTrend.graphData"/>
    </div>
    <category-trends-list :available-category-trends="availableCategoriesWithoutCurrent"/>

    <div class="margin-top-m padding-m " v-if="this.categoriesSteps.length > 0">
      <separator/>
      <div class="ri-bold-title margin-top-m">הצעדים הבאים שלך</div>
      <component v-for="step in categoriesSteps" :key="step.actionKey" :is="step.cardComponent" class="margin-top-m"/>
    </div>

  </dashboard-page>
</template>

<script>
import _ from 'lodash';
import * as HamsterApi from '@/api/HamsterApi';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import { getCashflowMonthFromMonthsAgo, getCurrentCashflowMonth, getCashflowMonth } from '@riseupil/common-utils';
import {
  ActionKeys, actionKeyToComponent,
  progressStatusToActionKeys,
} from '@/pages/responsive-pages/authenticated/navigation-pages/my-progress/next-steps/my-progress-next-steps.consts';
import dateUtils from '@/utils/dates';
import CategoryProgressOverview from './CategoryProgressOverview';
import CategoryTrendBusinessesCard from './CategoryTrendBusinessesCard';
import CategoryTrendGraphCard from './CategoryTrendGraphCard';
import CategoryTrendsList from '../CategoryTrendsList';
import CategoryQuarterVsQuarterGraph from './CategoryQuarterVsQuarterGraph';
import CategoryMonthVsAverageGraph from './CategoryMonthVsAverageGraph';

export default {
  name: 'CategoryProgressPage',
  components: {
    DashboardPage: BaseUI.Pages.DashboardPage,
    Separator: BaseUI.Separator,
    CategoryTrendsList,
    CategoryTrendBusinessesCard,
    CategoryProgressOverview,
    CategoryTrendGraphCard,
    CategoryQuarterVsQuarterGraph,
    CategoryMonthVsAverageGraph,
  },
  data() {
    return {
      categoryTrend: null,
    };
  },
  created() {
    this.initPage();
  },
  computed: {
    ...mapState('customerProgress', ['customerProgress']),
    ...mapGetters('customerProgress', ['availableCategoryTrends']),
    ...mapState('cashflowView', ['cashflowStartDay']),
    ...mapState('applicationState', ['activationDate']),
    isPositiveChange() {
      return this.categoryTrend.currentQuarterAmount > this.categoryTrend.lastQuarterAmount;
    },
    hideBadge() {
      return this.categoryTrend.categoryName === 'תרומה' || this.categoryTrend.categoryName === 'הפקדות חד פעמיות לחיסכון' || this.isFirstCashflow;
    },
    imageHeaderProps() {
      return {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
        bottomSeparator: false,
        textColor: BaseUI.Colors.riseupDarkGreen,
        imgSrc: this.isPositiveChange || this.hideBadge ? undefined : '/images/my-progress/negative-trend-category-dashboard-header.svg',
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.TOP_LEFT,
        imgHeight: 210,
      };
    },
    availableCategoriesWithoutCurrent() {
      return this.availableCategoryTrends.filter(category => category.categoryId !== this.categoryTrend.categoryId);
    },
    isFirstCashflow() {
      return getCashflowMonth({ date: this.activationDate, cashflowStartDay: this.cashflowStartDay })
          === getCurrentCashflowMonth(this.cashflowStartDay);
    },
    lastMonth() {
      return getCashflowMonthFromMonthsAgo(getCurrentCashflowMonth(this.cashflowStartDay), 1);
    },
    sixMonthsAgo() {
      return getCashflowMonthFromMonthsAgo(this.lastMonth, 5);
    },
    currentQuarterAmount() {
      if (!_.isNil(this.categoryTrend.currentQuarterAmount)) {
        return this.categoryTrend.currentQuarterAmount;
      }
      const pastThreeMonths = _.times(3, i => getCashflowMonthFromMonthsAgo(this.lastMonth, i));
      return _.sumBy(this.categoryTrend.graphData.filter(({ cashflowMonth }) => pastThreeMonths.includes(cashflowMonth)), 'amount');
    },
    lastQuarterAmount() {
      if (!_.isNil(this.categoryTrend.lastQuarterAmount)) {
        return this.categoryTrend.lastQuarterAmount;
      }
      const previousThreeMonths = _.times(3, i => getCashflowMonthFromMonthsAgo(this.lastMonth, i + 3));
      return _.sumBy(this.categoryTrend.graphData.filter(({ cashflowMonth }) => previousThreeMonths.includes(cashflowMonth)), 'amount');
    },
    prevCashflowMonth() {
      return getCashflowMonthFromMonthsAgo(getCurrentCashflowMonth(this.cashflowStartDay), 1);
    },
    prevCashflowMonthName() {
      return dateUtils.getShortHebrewMonth(this.prevCashflowMonth);
    },
    prevMonthAmount() {
      console.log(this.prevCashflowMonth);
      return _.sumBy(this.categoryTrend.graphData.filter(({ cashflowMonth }) => cashflowMonth === this.prevCashflowMonth), 'amount');
    },
    previousMonthsAverage() {
      const previousMonths = _.filter(this.categoryTrend.graphData, ({ cashflowMonth }) => cashflowMonth !== this.prevCashflowMonth);
      return _.meanBy(previousMonths, 'amount');
    },
    possibleSteps() {
      return [ActionKeys.CATEGORIES_STORY];
    },
    progressStatus() {
      return this.customerProgress?.progressState?.progressStatus;
    },
    categoriesSteps() {
      if (!this.progressStatus) {
        return [];
      }

      const customerSteps = progressStatusToActionKeys[this.progressStatus];
      return _.chain(this.possibleSteps)
        .filter(step => customerSteps.includes(step))
        .map(actionKey => {
          return { actionKey, ...actionKeyToComponent[actionKey] };
        }).value();
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('customerProgress', ['initCustomerProgress']),
    ...mapActions('cashflowView', ['initBudgets']),
    async initPage() {
      await this.initCustomerProgress();
      try {
        this.categoryTrend = await HamsterApi.getCategoryTrend(this.$route.params.categoryIdHash);
      } catch (error) {
        DDLogs.error('MyProgress_CategoryProgressPage_LoadError', {
          error,
          categoryIdHash: this.$route.params.categoryIdHash,
        });
        this.goToMyProgress();
        return;
      }
      if (!this.categoryTrend) {
        Segment.trackUserGot('MyProgress_CategoryProgressPage_CategoryDataNotFound', { categoryIdHash: this.$route.params.categoryIdHash });
        this.goToMyProgress();
        return;
      }
      Segment.trackUserGot('MyProgress_CategoryProgressPage_Entered', {
        categoryId: this.categoryTrend.categoryId,
        categoryName: this.categoryTrend.categoryName,
      });
      this.setAppHeaderProps({
        backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
        textColor: BaseUI.Colors.riseupDarkGreen,
        logoColor: BaseUI.Colors.riseupDarkGreen,
      });
    },
    goToMyProgress() {
      this.$router.push({ name: 'MyProgress' });
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.categoryIdHash !== from.params.categoryIdHash) {
        this.categoryTrend = null;
        this.initPage();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.header {
  text-align: right;
  color: $riseup_black;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: $ri-spacing-ml;
}
.category-cards {
  row-gap: $ri-spacing-ml;
}
</style>
